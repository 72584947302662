@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}
/*//* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 0.5rem;
}

*::-webkit-scrollbar-track {
	background: rgb(210, 253, 255);
	border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
	background-image: linear-gradient(to bottom, rgb(177, 222, 231), rgb(196, 255, 255), rgb(186, 226, 253));
	border-radius: 0.4rem;
	/* border: 3px solid rgb(76, 143, 210); */
}

/*//* firefox */
/* * {
	width: 0.3rem;
	scrollbar-color: rgb(186, 248, 251) rgb(160, 205, 210);

} */